import React, { useState } from 'react';
import './Product.css';
import { useSpring, animated } from '@react-spring/web';
import { Modal, Box, Button } from '@mui/material';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Product = () => {
    let publicUrl = process.env.PUBLIC_URL + '/';
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const history = useHistory();
    const [item, setItem] = useState();
    const apiMessage = [
        { apim: 'Form Pembuatan PT ezlegal.co.id', wam: 'Halo, Saya mau berkonsultasi mengenai Pembuatan PT' },
        { apim: 'Form Pembuatan PT Perseorangan ezlegal.co.id', wam: 'Halo, Saya mau berkonsultasi mengenai Pembuatan PT Perseorangan' },
        { apim: 'Form Pembuatan CV ezlegal.co.id', wam: 'Halo, Saya mau berkonsultasi mengenai Pembuatan CV' },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                lead_brand_id: '17',
                lead_name: name,
                lead_phone: phone,
                lead_source: apiMessage[item].apim,
            };
            await axios.post('https://api.ezkalasi.id/api/create-lead', dataToSend)
                .then(function (response) {
                    console.log('Success:', response.data);
                    const text = apiMessage[item].wam;
                    history.push('/directed-whatsapp', { name, text });
                })
                .catch(function (error) {
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handlePhone = (e) => {
        const input = e.target.value;
        // Menghapus karakter selain angka
        const formattedInput = input.replace(/\D/g, '');
        setPhone(formattedInput);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const animation = useSpring({
        opacity: open ? 1 : 0,
        transform: open ? 'translateY(0)' : 'translateY(-100%)',
    });

    const toggleIframe = (product) => {
        setItem(product - 1);
        handleOpen(true);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
            >
                <animated.div style={{ ...animation, ...modalStyle }}>
                    <Box sx={{ ...boxStyle }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='button-modals-close'>
                                <button variant="outlined" onClick={handleClose}>
                                    x
                                </button>
                            </div>

                            <img src='/assets/img/logo-ezlegal.png' style={{ height: '5em', objectFit: 'contain' }} />

                            <form onSubmit={handleSubmit}>
                                <div className='form-container'>
                                    <label>Nama:</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder='Nama'
                                        required
                                        style={{ borderColor: '#004391', borderWidth: '1px' }}
                                    />
                                </div>
                                <div className='form-container'>
                                    <label>No Hp:</label>
                                    <input
                                        type="text"
                                        value={phone}
                                        onChange={handlePhone}
                                        placeholder='No Hp'
                                        required
                                        style={{ borderColor: '#004391', borderWidth: '1px' }}
                                    />
                                </div>

                                <div className='button-modals'>
                                    <Button variant="contained" style={{ color: 'white', backgroundColor: '#004391' }} type='submit' sx={{ mt: 2 }}>
                                        Konsultasi Sekarang
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Box>
                </animated.div>
            </Modal>
            <div style={{ backgroundColor: '#EDF9FF', display: 'flex', justifyContent: 'center' }} id='section4'>
                <div className='product-width'>
                    <div style={{ display: 'flex' }}><h1 style={{ backgroundColor: '#66B28B', padding: '0.3em', paddingInline: '0.5em', borderRadius: '100vw', color: 'white' }}>Our Product</h1></div>
                    <div className='product-container'>
                        {[
                            {
                                img: 'assets/img/banner/used/a1.png',
                                diskon: false,
                                title: 'PT Perorangan',
                                price: '2.499 K',
                                details: [
                                    '✓ Konsultasi Legal UMKM',
                                    '✓ Pengecekan & Pendaftaran Nama PT',
                                    '✓ SKT Kemenkumham',
                                    '✓ Akun OSS RBA',
                                    '✓ NIB & Sertifikat Standar',
                                    '✓ NPWP',
                                    '✓ SKT KPP',
                                    'Bonus :',
                                    '✓ Stempel Perusahaan',
                                    '✓ Pembukaan Rekening Giro',
                                    '✓ Konsultasi Merek Usaha'
                                ],
                                order: 0
                            },
                            {
                                img: 'assets/img/banner/used/c.png',
                                diskon: true,
                                title: 'Pendirian PT',
                                originalPrice: '4.999.000',
                                price: '4.499 K',
                                details: [
                                    '✓ Konsultasi Legal UMKM',
                                    '✓ Pengecekan & Pendaftaran Nama PT',
                                    '✓ Akta Pendirian',
                                    '✓ SKT Kemenkumham',
                                    '✓ Akun OSS RBA',
                                    '✓ NIB & Sertifikat Standar',
                                    '✓ NPWP',
                                    '✓ SKT KPP',
                                    'Bonus :',
                                    '✓ Stempel Perusahaan',
                                    '✓ Pembukaan Rekening Giro',
                                    '✓ Konsultasi Merek Usaha',
                                    '✓ Gratis Virtual Office selama 1 Tahun'
                                ],
                                order: 1
                            },
                            {
                                img: 'assets/img/banner/used/b.png',
                                diskon: false,
                                title: 'Pembuatan CV',
                                price: '2.999 K',
                                details: [
                                    '✓ Konsultasi Legal UMKM',
                                    '✓ Pengecekan & Pendaftaran Nama CV',
                                    '✓ Akta Pendirian',
                                    '✓ SKT Kemenkumham',
                                    '✓ Akun OSS RBA',
                                    '✓ NIB & Sertifikat Standar',
                                    '✓ NPWP',
                                    '✓ SKT KPP',
                                    'Bonus :',
                                    '✓ Stempel Perusahaan',
                                    '✓ Pembukaan Rekening Giro',
                                    '✓ Konsultasi Merek Usaha'
                                ],
                                order: 2
                            }
                        ].map((product, index) => (
                            <div key={index} className={`order-${product.order}`} style={{ display: 'flex', flexDirection: 'column',position:'relative' }}>
                                <img src={product.img} alt={product.title} />
                                <div className='product-card'>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <h4 style={{ fontWeight: 'bold', color: '#66B28B' }}>{product.title}</h4>

                                        {product.originalPrice ?
                                            <>

                                                <h4 style={{ backgroundColor: 'tomato', width:'100%', textAlign: 'center',padding:'0.2em',position:'absolute',marginTop:'-50px',color:'white',fontWeight:'500'}}>Penawaran Terbatas %</h4>

                                                <h4 style={{ textDecoration: 'line-through', color: 'red', margin: 0 }}>Rp {product.originalPrice}</h4>
                                            </>
                                            : <br />}
                                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                            <h4>Rp</h4>
                                            <h2>{product.price}</h2>
                                        </div>
                                    </div>
                                    <div style={{ paddingInline: '2em' }}>
                                        {product.details.map((detail, i) => (
                                            <p key={i}>{detail}</p>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <button onClick={() => toggleIframe(product.order + 1)} style={{ width: '100%', paddingBottom: '1em', paddingTop: '1em', borderRadius: '0 0 2em 2em', backgroundColor: '#66B28B', color: 'white', fontWeight: 'bolder', fontSize: '1.3em' }}>Konsultasi Sekarang<img src={publicUrl + 'assets/img/banner/used/icon-like.png'} style={{ width: '2em' }} alt='icon' /></button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    outline: 'none',
};

const boxStyle = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    borderRadius: '1em',
    boxShadow: 24,
    p: 4,
    maxWidth: '600px', // max-width for larger screens
};

export default Product;
