import React, { useState } from 'react'
import "./Addon.css"
import { useSpring, animated } from '@react-spring/web';
import { Modal, Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Addon = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const history = useHistory();
    const [item, setItem] = useState();
    const apiMessage = [
        { apim: "Form EZPajak", wam: "Halo, Saya mau berkonsultasi mengenai jasa EZPajak" },
        { apim: "Form EZHaki", wam: "Halo, Saya mau berkonsultasi mengenai jasa EZHaki" },
        { apim: "Form EZAkuntan", wam: "Halo, Saya mau berkonsultasi mengenai jasa EZAkuntan" },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                lead_brand_id: '17',
                lead_name: name,
                lead_phone: phone,
                lead_source: apiMessage[item].apim
            };
            await axios.post('https://api.ezkalasi.id/api/create-lead', dataToSend)
                .then(function (response) {
                    console.log('Success:', response.data);
                    const text = apiMessage[item].wam;
                    history.push('/directed-whatsapp', { name, text });
                })
                .catch(function (error) {
                    console.error('Error:', error);
                });



        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handlePhone = (e) => {
        const input = e.target.value;
        // Menghapus karakter selain angka
        const formattedInput = input.replace(/\D/g, '');
        setPhone(formattedInput);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const animation = useSpring({
        opacity: open ? 1 : 0,
        transform: open ? 'translateY(0)' : 'translateY(-100%)',
    });

    const [link, setLink] = useState("");

    const toggleIframe = (product) => {
        setItem(product - 1)
        handleOpen(true);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
            >
                <animated.div style={{ ...animation, ...modalStyle }}>
                    <Box sx={{ ...boxStyle }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='button-modals-close' >
                                <button  variant="outlined"  onClick={handleClose}>
                                    x
                                </button>
                            </div>

                            <img src='/assets/img/logo-ezlegal.png' style={{ height: '5em', objectFit: 'contain' }} />

                            <form onSubmit={handleSubmit}>
                                <div className='form-container'>
                                    <label>Nama:</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder='Nama'
                                        required
                                        style={{borderColor:'#004391',borderWidth:'1px'}}
                                    />
                                </div>
                                <div className='form-container'>
                                    <label>No Hp:</label>
                                    <input
                                        type="text"
                                        value={phone}
                                        onChange={handlePhone}
                                        placeholder='No Hp'
                                        required
                                        style={{borderColor:'#004391',borderWidth:'1px'}}
                                    />
                                </div>

                                <div className='button-modals' >

                                    <Button variant="contained" style={{ color: 'white', backgroundColor: '#004391' }} type='submit' sx={{ mt: 2 }}>
                                        Konsultasi Sekarang
                                    </Button>


                                </div>

                            </form>
                        </div>

                    </Box>
                </animated.div>
            </Modal>
            <div style={{ backgroundColor: '#EDF9FF', display: 'flex', justifyContent: 'center', paddingBottom: '3em', paddingTop: '3em' }} id='section5'>
                <div className='addon-width'>
                    <h5 style={{ color: '#004391' }}>+ Service Tambahan</h5>
                    <div className='addon-container'>
                        <div className='addon-card'>
                            <div>
                                <img src='assets/img/banner/used/addon-2.png' />
                            </div>
                            <div className='addon-textcontainer'>
                                <h4 style={{ marginBottom: 0 }}>EZPajak</h4>
                                <button onClick={() => toggleIframe(1)} className='button-cta-addon'><img src='assets/img/banner/used/icon-chat.png' style={{ width: '3em' }} /></button>
                            </div>

                        </div>
                        <div className='addon-card'>
                            <div>
                                <img src='assets/img/banner/used/addon-1.png' />
                            </div>
                            <div className='addon-textcontainer'>
                                <h4 style={{ marginBottom: 0 }}>EZHaki</h4>
                                <button onClick={() => toggleIframe(2)} className='button-cta-addon'><img src='assets/img/banner/used/icon-chat.png' style={{ width: '3em' }} /></button>
                            </div>

                        </div>
                        <div className='addon-card'>
                            <div>
                                <img src='assets/img/banner/used/addon-3.png' />
                            </div>
                            <div className='addon-textcontainer'>
                                <h4 style={{ marginBottom: 0 }}>EZAkuntan</h4>
                                <button onClick={() => toggleIframe(3)} className='button-cta-addon'><img src='assets/img/banner/used/icon-chat.png' style={{ width: '3em' }} /></button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}
const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    outline: 'none',
};

const boxStyle = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    borderRadius: '1em',

    boxShadow: 24,
    p: 4,
    maxWidth: '600px', // max-width for larger screens
};

export default Addon
