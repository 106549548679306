import React from 'react'

const ProductBanner = ({wording}) => {
    let publicUrl = process.env.PUBLIC_URL + '/';
    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ backgroundImage: `url("${publicUrl}assets/img/banner/subproduct/backround.jpg")`, position: 'relative', width: '80%', height: '70vh', backgroundSize: 'cover', borderRadius: '3em' }}>
                <div style={{ display:'flex',flexDirection:'column',justifyContent:'center',height:'100%', marginLeft:'10%'}}>
                    <h1 style={{ color: 'white', lineHeight: "1.3em" }}>
                        {wording.bannerTitleOne}<br /> <span style={{ backgroundColor: 'white', color: "#004391", paddingInline: '0.2em', }}>{wording.bannerTitleTwo}</span>
                    </h1>
                    <p style={{ color: 'white', margin: 0, width: '55%', paddingTop: '1em', paddingBottom: '1em', fontSize: '1.3em', fontWeight: '700', lineHeight: "1.3em" }}>
                        {wording.bannerSubtitle}
                    </p>
                    <p style={{ color: 'white', margin: 0, fontSize: '1.3em', fontWeight: '600' }}><span style={{ color: '#65D88B', fontWeight: 'bolder' }}>✓ </span>Kepemilikan Tunggal</p>
                    <p style={{ color: 'white', margin: 0, fontSize: '1.3em', fontWeight: '600' }}><span style={{ color: '#65D88B', fontWeight: 'bolder' }}>✓ </span>Administrasi Tidak Berbelit</p>
                    <p style={{ color: 'white', margin: 0, fontSize: '1.3em', fontWeight: '600' }}><span style={{ color: '#65D88B', fontWeight: 'bolder' }}>✓ </span>Rahasia perusahaan Terjamin</p>
                </div>
                <img src={wording.bannerImg} style={{ height: '100%', position: 'absolute', right: 90,top:0, zIndex: '1500' }} />
                <img src='assets/img/banner/subproduct/sunburst.png' style={{ position: 'absolute', right: 0, top: -80, height: '150%', opacity: '10%' }} />

            </div>
            <div>
                <div className='segitiga-about' >
                    <div style={{
                        width: 0,
                        height: 0,
                        borderLeft: '100px solid transparent',
                        borderRight: '100px solid transparent',
                        borderTop: '80px solid #588DB5',
                    }}></div>
                </div>
            </div>
        </div>
    )
}

export default ProductBanner
