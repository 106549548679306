import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const Directed = () => {
  const location = useLocation();
  const { name, text } = location.state || {};

  useEffect(() => {
    const delay = 5000; // 5 seconds
    const waNumber = '6282229299968';
    const redirectUrl = `https://wa.me/${waNumber}?text=${encodeURIComponent(text)}`; // Your target URL

    const timer = setTimeout(() => {
      window.location.replace(redirectUrl);
    }, delay);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div style={{display:'flex', flexDirection:'column',height:"100vh",justifyContent:'center',alignItems:'center'}}>
      <h1>Thank You!</h1>
      <h3 style={{fontWeight:'lighter'}}>Anda akan segera terhubung dengan EZLegal melalui WhatsApp.</h3>
    </div>
  )
}

export default Directed
