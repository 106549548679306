import React from 'react'

const ProductDecription = ({wording}) => {
  return (
    <div style={{display:'flex',justifyContent:'center',paddingTop:'10em',paddingBottom:'10em'}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'70%', height:'70vh',position:'relative'}}>
            <img src='assets/img/banner/subproduct/placeholder.jpg' style={{width:'30%', height:'100%',borderRadius:'1em'}}/>
            <div style={{backgroundColor:'#F1F3F2', height:'70%', width:'100%',padding:'2em',borderRadius:'0px 1em 1em 0px'}}>
                <h2 style={{color:'#508DD0'}}>{wording.descTitle}</h2>
                <h3 style={{fontWeight:'500',color:'black'}}>{wording.descSubtitle}</h3>
            </div>
            <img src='assets/img/banner/subproduct/icon-ask.png' style={{height:'7em',position:'absolute',top:-20,right:-50}}/>
      </div>
    </div>
  )
}

export default ProductDecription
