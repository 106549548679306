import React from 'react'
import NavbarV2 from './global-components/navbar-v2'
import ProductBanner from './section-components/product-banner'
import ProductDecription from './section-components/product-description'
import ProductBenefit from './section-components/product-benefit'
import Product from './section-components/Product'
import Footer_v1 from './global-components/footer'
import FloatingWhatsAppButton from './section-components/FloatingWhatsAppButton'
import { CssBaseline } from '@mui/material'
import useProduct from '../data/store/store'

const SubProduct = () => {
  const productWording = [
    {
      bannerTitleOne:'Untuk Kamu Yang', 
      bannerTitleTwo:'Serba Mandiri',
      bannerSubtitle:'PT Perorangan bisa menjadi solusi bagi kamu pemilik bisnis yang lebih suka mengurus semuanya sendiri!',
      bannerImg:'assets/img/banner/subproduct/gedung.png',
      descTitle:'PT Perorangan itu apasih?',
      descSubtitle:'Jenis peusahaan dimana kamu bertanggung jawab penuh sebagai pemilik tunggal namun memiliki bentuk hukum yang terpisah dari aset pribadi.',
      descImg:'assets/img/banner/subproduct/placeholder.png'
    },
    {
      bannerTitleOne:'Kecil-Kecil', 
      bannerTitleTwo:'Cabe Rawit',
      bannerSubtitle:'Kerjasama Bisnis dalam badan usaha non hukum yang fleksibel dengan tanggung jawab berdasarkan peran.',
      bannerImg:'assets/img/banner/subproduct/rumah.png',
      descTitle:'Commanditaire Vennootschap (CV) itu apasih?',
      descSubtitle:'Didirikan oleh satu atau lebih sekutu komanditer dan sekutu komplementer untuk menjalankan usaha secara terus menerus. Dengan mendirikan CV, kamu bisa menggabungkan sumber daya dan keterampilan dari berbagai pihak untuk memaksimalkan potensi bisnismu.',
      descImg:'assets/img/banner/subproduct/placeholder.png'
    },
    {
      bannerTitleOne: 'Aset Pribadi Aman', 
      bannerTitleTwo:'Terlindungi',
      bannerSubtitle:'Perusahaan berbentuk umum bikin bisnis lebih gampang berkembang dna makin mudah di akses.',
      bannerImg:'assets/img/banner/subproduct/perkantoran.png',
      descTitle:'PT Reguler itu apasih?',
      descSubtitle:'Badan hukum yang bisa melindungi aset pribadi kamu dari resiko bisnis. Dengan memiliki entitas hukum yang terpisah, kewajiban bisnis tidak akan membebani harta pribadimu dan mengurangi resiko yan di tanggung.',
      descImg:'assets/img/banner/subproduct/placeholder.png'
    }

  ]
  const {productId,setProductId} = useProduct();
  return (
    <div>
      <h1>here is your productid : {productId}</h1>
      <h3>here is your title{productWording[productId-1].bannerTitleTwo}</h3>
      <button onClick={()=>setProductId(1)}>1</button>
      <button onClick={()=>setProductId(2)}>2</button>
      <button onClick={()=>setProductId(3)}>3</button>
      <NavbarV2/>
      <ProductBanner wording={productWording[productId-1]}/>
      <ProductDecription wording={productWording[productId-1]}/>
      <ProductBenefit />
      <Product />
      <Footer_v1 />
      <FloatingWhatsAppButton />
      <CssBaseline />
    </div>
  )
}

export default SubProduct
