import React, { useState } from 'react';
import { Link } from 'react-scroll';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const NavbarV2 = ({ CustomClass }) => {

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const history = useHistory();
    const [item, setItem] = useState();
    const apiMessage = [
        { apim: "Konsultasi Sekarang", wam: "Halo, Saya mau berkonsultasi mengenai jasa EZlegal" },
       
    ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSend = {
                lead_brand_id: '17',
                lead_name: name,
                lead_phone: phone,
                lead_source: apiMessage[item].apim
            };
            await axios.post('https://api.ezkalasi.id/api/create-lead', dataToSend)
                .then(function (response) {
                    console.log('Success:', response.data);
                    const text = apiMessage[item].wam;
                    history.push('/directed-whatsapp', { name, text });
                })
                .catch(function (error) {
                    console.error('Error:', error);
                });



        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handlePhone = (e) => {
        const input = e.target.value;
        // Menghapus karakter selain angka
        const formattedInput = input.replace(/\D/g, '');
        setPhone(formattedInput);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let publicUrl = process.env.PUBLIC_URL + '/';
    CustomClass = CustomClass ? CustomClass : '';

    const toggleIframe = (product) => {
        setItem(product - 1)
        handleOpen(true);
    };

    return (
        <div>
            <header className={"ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4--- " + CustomClass}>
                {/* ltn__header-top-area start */}

                {/* ltn__header-top-area end */}
                {/* ltn__header-middle-area start */}
                <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                        <div className='row' style={{ width: '80vw' }}>
                            <div className="col">
                                <div className="site-logo-wrap">
                                    <div className="site-logo go-top">
                                        <Link to="/"><img src={publicUrl + "assets/img/logo-ezlegal.png"} alt="Logo" style={{ height: '2em' }} /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col header-menu-column">
                                <div className="header-menu d-none d-xl-block go-top">
                                    <nav>
                                        <div className="ltn__main-menu" >
                                            <ul style={{ justifyContent: 'end' }}>
                                                <li><Link
                                                    activeClass="active"
                                                    to="section1"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500} >Home</Link></li>
                                                <li><Link
                                                    activeClass="active"
                                                    to="section2"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={500}>About Us</Link></li>
                                                <li><Link
                                                    activeClass="active"
                                                    to="section4"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={70}
                                                    duration={500} >Product</Link></li>

                                                <li style={{ color: 'white', backgroundColor: '#5291D6', fontWeight: 'bold', borderRadius: '0.5em', justifyContent: 'center', display: 'flex', alignItems: 'center', }}>
                                                    <Button onClick={() => toggleIframe(1)} style={{ color: 'white', fontWeight: 'bold' }}>Konsultasi Sekarang <img src='assets/img/banner/used/icon-call.png' style={{ height: '2em' }} /></Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                            <div className="col--- ltn__header-options ltn__header-options-2 ">
                                {/* Mobile Menu Button */}
                                <div className="mobile-menu-toggle d-xl-none">
                                    <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
                                        <svg viewBox="0 0 800 600">
                                            <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
                                            <path d="M300,320 L540,320" id="middle" />
                                            <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__header-middle-area end */}
            </header>
            <div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
                <div className="ltn__utilize-menu-inner ltn__scrollbar">
                    <div className="ltn__utilize-menu-head">
                        <div className="site-logo">
                            <Link to="/"><img src={publicUrl + "assets/img/logo-ezlegal.png"} alt="Logo" /></Link>
                        </div>
                        <button className="ltn__utilize-close">×</button>
                    </div>

                    <div className="ltn__utilize-menu">
                        <ul>
                            <li><Link activeClass="active"
                                to="section1"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}>Home</Link></li>
                            <li><Link activeClass="active"
                                to="section2"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}>About</Link></li>

                            <li><Link activeClass="active"
                                to="section4"
                                spy={true}
                                smooth={true}
                                offset={70}
                                duration={500}>Product</Link></li>
                        </ul>
                    </div>

                    <div className="ltn__social-media-2">
                        <ul>
                            <li><a href="https://www.facebook.com/ezlegal.co.id" title="Facebook"><i className="fab fa-facebook-f" /></a></li>

                            <li><a href="https://www.instagram.com/ezlegal.co.id" title="Instagram"><i className="fab fa-instagram" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...boxStyle }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='button-modals-close' >
                                <button  variant="outlined"  onClick={handleClose}>
                                    x
                                </button>
                            </div>

                            <img src='/assets/img/logo-ezlegal.png' style={{ height: '5em', objectFit: 'contain' }} />

                            <form onSubmit={handleSubmit}>
                                <div className='form-container'>
                                    <label>Nama:</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder='Nama'
                                        required
                                        style={{borderColor:'#004391',borderWidth:'1px'}}
                                    />
                                </div>
                                <div className='form-container'>
                                    <label>No Hp:</label>
                                    <input
                                        type="text"
                                        value={phone}
                                        onChange={handlePhone}
                                        placeholder='No Hp'
                                        required
                                        style={{borderColor:'#004391',borderWidth:'1px'}}
                                    />
                                </div>

                                <div className='button-modals' >

                                    <Button variant="contained" style={{ color: 'white', backgroundColor: '#004391' }} type='submit' sx={{ mt: 2 }}>
                                        Konsultasi Sekarang
                                    </Button>


                                </div>

                            </form>
                        </div>

                </Box>
            </Modal>
        </div>
    );
}
const boxStyle = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    borderRadius: '1em',

    boxShadow: 24,
    p: 4,
    maxWidth: '600px', // max-width for larger screens
};
export default NavbarV2;
