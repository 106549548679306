import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring';
import "./Review.css"

const cards = [
    {
        nama: 'Andang K',
        komentar: 'Urusan PT, Pajak, dlsb gampang banget diurus sampe jadi',
        image: './assets/img/banner/used/profile-1.png',

    },
    {
        nama: 'Regina Alsyid',
        komentar: 'Paket lengkap deh disini, asistennya juga ramah dijelasin dan dibantu sampai proses selesai.',
        image: './assets/img/banner/used/profile-2.png',

    },
    {
        nama: 'Michael',
        komentar: 'Semua Urusan gampang karena di bantu prosesnya dari awal sampe akhir secara profesional.',
        image: './assets/img/banner/used/profile-3.png',

    },
    {
        nama: 'Junaidi',
        komentar: 'prosesnya cepat dan sangat profesional setiap prosesnya dibantu dengan sangat baik semua pertanyaan di bimbing dengan baik.',
        image: './assets/img/banner/used/profile-4.png',

    },
];

const Review = () => {
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState('next');

    const transitions = useTransition(index, {
        from: {
            opacity: 0,
            transform: direction === 'next' ? 'translate3d(100%,0,0)' : 'translate3d(-100%,0,0)'
        },
        enter: {
            opacity: 1,
            transform: 'translate3d(0%,0,0)'
        },
        leave: {
            opacity: 0,
            transform: direction === 'next' ? 'translate3d(-50%,0,0)' : 'translate3d(50%,0,0)'
        },
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setDirection('next');
            setIndex((prevIndex) => (prevIndex + 2) % cards.length);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ backgroundColor: '#FCFCFC', display: 'flex', justifyContent: 'center', paddingBottom: '3em', paddingTop: '3em' }} id='section6'>
            <div className='review-container'>
                <div className='review-textcontainer' >
                    <div style={{ display: 'flex' }}><h5 className="gradient-background-review">Review</h5></div>
                    <h1>Kata Mereka Tentang Kami</h1>
                </div>

                <div className="card-slider-review">

                    <div className="card-container">
                        {transitions((style, i) => (
                            <animated.div className="card" style={style}>
                                <div className='card-layout' >
                                    <div className='review-card'>
                                        <img src={cards[i].image} style={{ height: '5em', width: '5em', objectFit: 'cover', borderRadius: '100vw', marginBottom: '1em' }} />
                                        <h4 style={{ marginBottom: 0 }}>{cards[i].nama}</h4>
                                        <p style={{ marginBottom: 0, flexWrap: 'nowrap', flexGrow: 1 }}>{cards[i].komentar}</p>
                                    </div>
                                    <div className='review-card'>
                                        <img src={cards[i + 1].image} style={{ height: '5em', width: '5em', objectFit: 'cover', borderRadius: '100vw', marginBottom: '1em' }} />
                                        <h4 style={{ marginBottom: 0 }}>{cards[i + 1].nama}</h4>
                                        <p style={{ marginBottom: 0, flexWrap: 'nowrap', flexGrow: 1 }}>{cards[i + 1].komentar}</p>
                                    </div>
                                </div>
                            </animated.div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Review
