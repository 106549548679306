import React from 'react'

const ProductBenefit = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '110vh' }}>
      <div style={{ background: 'radial-gradient(circle, #7EBA95 , #5D9F77 )', width: '70%', borderRadius: '1em', height: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        <img src='assets/img/banner/subproduct/title.png' style={{ width: '30em', objectFit: 'cover', height: 'fit-content', position: 'absolute', top: -100 }} />
        <div style={{padding:'2em', paddingTop:'6em'}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center', paddingBottom:'1.5em'}}>
            <img src='assets/img/banner/subproduct/checklist.png' style={{ height: '90px',backgroundColor: '#fff', borderRadius: '100%',marginRight:'-15%',zIndex:1000,padding:'1em'}} />
            <h3 style={{backgroundColor:'#fff',padding:'0.2em 2em 0.2em 6em',borderRadius:'0.5em', color:'#5D9F77',width:'90%',fontWeight:500}}>Konsultasikan Kebutuhan Secara Gratis</h3>
          </div>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center', paddingBottom:'1.5em'}}>
            <img src='assets/img/banner/subproduct/checklist.png' style={{ height: '90px',backgroundColor: '#fff', borderRadius: '100%',marginRight:'-15%',zIndex:1000,padding:'1em'}} />
            <h3 style={{backgroundColor:'#fff',padding:'0.2em 2em 0.2em 6em',borderRadius:'0.5em', color:'#5D9F77',width:'90%',fontWeight:500}}>Proses Cepat Tidak Berbelit</h3>
          </div>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center', paddingBottom:'1.5em'}}>
            <img src='assets/img/banner/subproduct/checklist.png' style={{ height: '90px',backgroundColor: '#fff', borderRadius: '100%',marginRight:'-15%',zIndex:1000,padding:'1em'}} />
            <h3 style={{backgroundColor:'#fff',padding:'0.2em 2em 0.2em 6em',borderRadius:'0.5em', color:'#5D9F77',width:'90%',fontWeight:500}}>Legalitas Perusahaan Yang Terjamin</h3>
          </div>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center', paddingBottom:'1.5em'}}>
            <img src='assets/img/banner/subproduct/checklist.png' style={{ height: '90px',backgroundColor: '#fff', borderRadius: '100%',marginRight:'-15%',zIndex:1000,padding:'1em'}} />
            <h3 style={{backgroundColor:'#fff',padding:'0.2em 2em 0.2em 6em',borderRadius:'0.5em', color:'#5D9F77',width:'90%',fontWeight:500}}>Technical Support Siap Siaga</h3>
          </div>
        </div>

      </div>
      <div className='segitiga-about' >
                    <div style={{
                        width: 0,
                        height: 0,
                        borderLeft: '100px solid transparent',
                        borderRight: '100px solid transparent',
                        borderTop: '80px solid #5D9F77',
                    }}></div>
                </div>
    </div>
  )
}

export default ProductBenefit
