import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-v2';
import Aboutv3 from './section-components/about-v3';
import Footer from './global-components/footer';
import Story from './section-components/Story';
import Product from './section-components/Product';
import Review from './section-components/Review';
import Addon from './section-components/Addon';
import { CssBaseline } from '@mui/material';
import FloatingWhatsAppButton from './section-components/FloatingWhatsAppButton';
const Home_V2 = () => {
    return <div>
        <Navbar />
        <Banner />
        <Aboutv3 />
        <Story />
        <Product />
        <Addon />
        <Review />
        <Footer />
        <FloatingWhatsAppButton />
        <CssBaseline />

    </div>
}

export default Home_V2

